(function ($, site) {
  Drupal.behaviors.footerCountryChooserV1 = {
    attach: function (context) {
      if (this.attached) {
        return;
      }
      this.attached = true;

      site.util = site.util || {};
      site.util.refreshSelectBoxes = site.util.refreshSelectBoxes || function () {};

      var $body = $('body', context);
      var currentLocale = $body.attr('data-locale');
      var $template = $('.js-footer-country-chooser-v1', context);
      var $countryContainer = $('.js-footer-country-chooser__menu', $template);
      var $menu = $('.field-menu, .field-mobile-menu', $countryContainer);
      var $links = $('a', $menu);
      var $select = $('.js-footer-country-chooser__selectbox', $template);
      var $options = [];
      var $selectedOption = currentLocale === 'locale_en_us' ? $menu.find('.first') : $menu.find('.last');

      $selectedOption.addClass('selected');
      $menu.prepend('<div class="menu-active-value">' + $selectedOption.text() + '</div>');

      var defaultCountry = $select.attr('default-country');

      if (defaultCountry) {
        defaultCountry = defaultCountry.toLowerCase();
      }

      $links.each(function () {
        var $me = $(this),
          href = $me.attr('href'),
          text = $me.text();
        var attr = '';

        if (text.toLowerCase() === defaultCountry) {
          attr += ' selected="selected"';
        }

        $options.push('<option value="' + href + '"' + attr + '>' + text + '</option>');
      });
      $select.append($options);
      $select.next().attr({
        'role': 'combobox',
        'aria-haspopup': 'true',
        'aria-expanded': 'false'
      });
      site.util.refreshSelectBoxes($select);

      $select.on('change', function () {
        var loc = $(this).val();

        if (loc) {
          window.location = loc;

          return true;
        }
      });

      var $selectedCountry = $('.menu-active-value', $menu);

      $selectedCountry.on('click', function (e) {
        e.stopPropagation();
        $countryContainer.toggleClass('country-en');
      });
      $body.on('click', function (e) {
        if (!$(this).closest($menu).length) {
          $countryContainer.removeClass('country-en');
        }
      });
    },
    attached: false
  };
})(jQuery, window.site || {});
